<template>
  <div class="postPage">
    <div v-if="isLoading" class="loader-wrapper">
      <circles-to-rhombuses-spinner
        :animation-duration="1200"
        :circles-num="3"
        :circle-size="15"
        color="#585854"
      />
    </div>
    <template v-else>
      <div v-if="restaurant && restaurant.menu" class="postPage__line-middle">
        <pdf :src="restaurant.menuUrl">
          <template slot="loading">
            <div class="loader-wrapper">
              <circles-to-rhombuses-spinner
                :animation-duration="1200"
                :circles-num="3"
                :circle-size="15"
                color="#585854"
              /></div></template
        ></pdf>
      </div>
    </template>
  </div>
</template>

<script>
import { DataFactory } from "@/services/Api/DataFactory.js";
const restaurants = DataFactory.get("restaurants");
import pdf from "pdfvuer";

import { CirclesToRhombusesSpinner } from "epic-spinners";
export default {
  name: "LocalMenu",
  components: {
    //VuePdfEmbed,
    CirclesToRhombusesSpinner,
    pdf,
  },
  data() {
    return {
      restaurant: null,
      isLoading: false,
      file: null,
    };
  },
  async created() {
    this.$store.commit("changeStateVariable", {
      variable: "showOnTableReceiveType",
      data: true,
    });
    await this.getRestaurants();
  },
  methods: {
    async getRestaurants() {
      try {
        this.isLoading = true;
        const { data } = await restaurants.getRestaurants();
        if (this.$route.query.restaurantIdFromQr) {
          this.restaurant = data.find(
            (restaurant) =>
              restaurant.id == this.$route.query.restaurantIdFromQr
          );
          await this.$store.commit("submitRestrant", {
            restaurantId: this.restaurant.id,
            restaurant: { fromLocalMenu: true, ...this.restaurant },
          });
          this.$store.commit("changeReceiveType", "restaurant");
        } else this.restaurant = data[0];
        this.restaurant.menuUrl = `${this.$store.state.menuURL}/${this.restaurant.menu}`;
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.postPage {
  min-height: calc(100vh - 225px - var(--headerHeight));
}
.postPage__line-middle {
  max-width: 100vw;
}
.loader-wrapper {
  min-height: calc(100vh - 225px - var(--headerHeight));
}
</style>
